import React, { useState } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import * as styles from "./news.module.scss"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import Layout from "../components/layout"
import HeaderBanner from "../components/common/headerBanner/headerBanner"
import { NewsContainer } from "../components/sections/news/newsContainer"
import { NEWS_DATA } from "../data/news"

import { getAnnouncementNews, getAwardsNews } from "../utils/news-utils"

export default () => {
  const [newsView, setNewsView] = useState("announcements")

  return (
    <Layout>
      <HeaderBanner title="News & Updates" />
      <div className={styles.pageContainer}>
        <Breadcrumb firstLink="/news" firstLinkTitle="News" />
        <div className={styles.pageText}>
          <ul className={styles.newsViewContainer}>
            <li
              className={styles.newsViewLink}
              onClick={() => setNewsView("announcements")}
              style={
                newsView === "announcements"
                  ? {
                      color: "#0dc47c",
                    }
                  : null
              }
            >
              Announcements
            </li>
            <li
              className={styles.newsViewLink}
              onClick={() => setNewsView("awards")}
              style={
                newsView === "awards"
                  ? {
                      color: "#0dc47c",
                    }
                  : null
              }
            >
              Awards & Publications
            </li>
            <OutboundLink href="http://blog.01com.com/wp/" target="_blank">
              <li className={styles.newsViewLink}>Blog</li>
            </OutboundLink>
          </ul>

          {newsView === "announcements" && (
            <div className={styles.postsContainer}>
              {getAnnouncementNews(NEWS_DATA).map((newsPair, i) => (
                <NewsContainer
                  first={newsPair.first}
                  second={newsPair.second}
                  key={`news-container-${i}`}
                />
              ))}
            </div>
          )}
          {newsView === "awards" && (
            <div className={styles.postsContainer}>
              {getAwardsNews(NEWS_DATA).map((newsPair, i) => (
                <NewsContainer
                  first={newsPair.first}
                  second={newsPair.second}
                  key={`news-container-${i}`}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
